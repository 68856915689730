import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/index.css'
import '../css/aos.css'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const IndexPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'home'
      }}
      title="Home | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="home">
				<div className="content clearfix">
					<div className="header-image" data-relative-input="true">
						<div className="header-content">
							<div className="header-title">
                                <div className="a-logo-box">
                                    <div className="a-logo-left a-logo" data-aos = "fade-right" data-aos-duration = "1100">
                                        <img src='/images/a-logo-left.png' alt="" />
                                    </div>
                                    <div className="a-logo-right a-logo" data-aos = "fade-left" data-aos-duration = "1100">
                                        <img src='/images/a-logo-right.png' alt="" />
                                    </div>
                                </div>
								{/* <div className="content-title">
									<h1 data-aos = "fade-up"><FormattedHTMLMessage id="homeMainTitle" /></h1>
								</div> */}
							</div>

							<div className="semi-transparent-box">
								<h2 data-aos = "fade-up"><FormattedMessage id="homeTitle" /></h2>
								<p data-aos = "fade-up" data-aos-duration = "500"><FormattedMessage id="homeSubtitle" /></p>
								<a data-aos = "fade-left" data-aos-duration = "900" href="/studio" className="button bkg-pinterest border-hover-pinterest color-white color-hover-pinterest">
									<FormattedMessage id="homeButton" />
									</a>
							</div>

							<div className="header-icons">
								<div className="icons-title">
									<h3><FormattedMessage id="homeSettori" /></h3>
								</div>
								<div className="icons">
									<div className="single-icon" data-aos = "fade-up" >
										<a href="/settori#famiglia">
											<img src='images/home/diritto-famiglia.png' />
											<p><FormattedMessage id="homeFamiglia" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "500">
										<a href="/settori#contenzioso">
											<img src='images/home/contenzioso.png' />
											<p><FormattedMessage id="homeContenzioso" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "800">
										<a href="/settori#bancario">
											<img src='images/home/diritto-bancario.png' />
											<p><FormattedMessage id="homeBancario" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "1100">
										<a href="/settori#penale">
											<img src='images/home/diritto-penale.png' />
											<p><FormattedMessage id="homePenale" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "1300">
										<a href="/settori#amministrativo">
											<img src='images/home/diritto-amministrativo.png' />
											<p><FormattedMessage id="homeAmministrativo" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "1500">
										<a href="/settori#fallimentare">
											<img src='images/home/diritto-fallimentare.png' />
											<p><FormattedMessage id="homeFallimentare" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "1700">
										<a href="/settori/#crediti">
											<img src='images/home/recupero-crediti.png' />
											<p><FormattedMessage id="homeCrediti" /></p>
										</a>
									</div>
									<div className="single-icon" data-aos = "fade-up" data-aos-duration = "1900">
										<a href="/settori#contrattualistica">
											<img src='images/home/contrattualistica.png' />
											<p><FormattedMessage id="homeContrattualistica" /></p>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="avvocati">
						<div className="item">
							<h3 data-aos = "fade-up"><FormattedMessage id="homeSecondTitle" /></h3>
							{/* <p data-aos = "fade-up"><FormattedMessage id="homeSecondSubtitle" /></p> */}
							<p data-aos = "fade-up" data-aos-duration = "500"><FormattedHTMLMessage id="homeLawyers" /></p>
							<a data-aos = "fade-up" data-aos-duration = "900" href="/team" className="button bkg-pinterest border-hover-pinterest color-white color-hover-pinterest">
								<FormattedMessage id="homeButton" /></a>
						</div>
					</div>
					<div className="avvocati-img"></div>
				</div>
			</div>
		</Layout >
	</>
)

export default IndexPage
